var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t('link-images')))]),_c('b-field',{attrs:{"horizontal":"","label":_vm.$t('curtain')}},[_c('b-select',{attrs:{"size":"is-small","expanded":""},model:{value:(_vm.curtainImage),callback:function ($$v) {_vm.curtainImage=$$v},expression:"curtainImage"}},[_c('option',{domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.$t('none'))+" ")]),_vm._l((_vm.unselectedCurtainImages),function(image){return _c('option',{key:image.id,domProps:{"value":image}},[_vm._v(" "+_vm._s(image.instanceFilename)+" ")])})],2)],1),(_vm.nbImages > 1)?_c('b-field',{attrs:{"horizontal":"","label":_vm.$t('link-mode')}},[_c('b-select',{attrs:{"size":"is-small","expanded":""},model:{value:(_vm.linkMode),callback:function ($$v) {_vm.linkMode=$$v},expression:"linkMode"}},_vm._l((_vm.modeOptions),function(option){return _c('option',{key:option.label,domProps:{"value":option.key}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)],1):_vm._e(),(_vm.nbImages > 1)?[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_vm._m(0),_c('th',[_vm._v(" "+_vm._s(_vm.$t('link-view-with')))])])]),_c('tbody',[_vm._l((_vm.linkedIndexes),function(index){return _c('tr',{key:index},[_c('td',[_c('b-checkbox',{attrs:{"value":true},on:{"input":_vm.unlink}})],1),_c('td',[_vm._v(" "+_vm._s(_vm.$t('viewer-view', {number: _vm.imagesWithNum[index].number}))+" ("),_c('image-name',{attrs:{"image":_vm.imagesWithNum[index].image}}),_vm._v(") ")],1)])}),_vm._l((_vm.otherGroups),function(ref){
var images = ref.images;
var index = ref.index;
var number = ref.number;
return _c('tr',{key:("group" + number)},[_c('td',[_c('b-checkbox',{attrs:{"value":false},nativeOn:{"change":function($event){return (function (event) { return _vm.handleCheckboxChange(event, index); })($event)}}})],1),_c('td',[_vm._v(" "+_vm._s(_vm.$t('link-group', {number: number}))+" "),_c('ul',{staticClass:"group"},_vm._l((images),function(indexImage){return _c('li',{key:indexImage},[_c('i',{staticClass:"fas fa-caret-right"}),_vm._v(" "+_vm._s(_vm.$t('viewer-view', {number: _vm.imagesWithNum[indexImage].number}))+" ("),_c('image-name',{attrs:{"image":_vm.imagesWithNum[indexImage].image}}),_vm._v(") "),_c('br')],1)}),0)])])}),_vm._l((_vm.otherSoloImages),function(ref){
var image = ref.image;
var index = ref.index;
var number = ref.number;
return _c('tr',{key:index},[_c('td',[_c('b-checkbox',{attrs:{"value":false},nativeOn:{"change":function($event){return (function (event) { return _vm.handleCheckboxChange(event, null, index); })($event)}}})],1),_c('td',[_vm._v(" "+_vm._s(_vm.$t('viewer-view', {number: number}))+" ("),_c('image-name',{attrs:{"image":image}}),_vm._v(") ")],1)])})],2)])]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_c('span',{staticClass:"fas fa-link"})])}]

export { render, staticRenderFns }